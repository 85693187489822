<template>
  <div>
    <h1>{{ $meta.title }}</h1>
    <div v-html=""></div>
    <p>This is my page content.</p>
  </div>
</template>

<script>


export default {
  metaInfo: {
    title: 'My Page Title',
    titleTemplate: '%s - Yay!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      { name: 'description', content: 'My Page Description' },
      { property: 'og:title', content: 'My Open Graph Title' },
      { property: 'og:description', content: 'My Open Graph Description' },
      { property: 'og:image', content: 'http://example.com/image.jpg' }
    ]
  }
}
</script>
